import { RadioGroup as MUIRadioGroup } from '@mui/material';
import { Typography } from '@mui/material';
import { RadioGroupProps } from './RadioGroup.types';
import { FormControl, FormControlLabel, Radio } from './RadioGroup.css';
import { FormFieldAction, trackFormFieldEvent } from '@frontend/tracking';

export default function RadioGroup({
  label,
  options,
  formId,
  onChange,
  id,
  ...rest
}: RadioGroupProps) {
  const trackFieldEvent = (
    action: FormFieldAction,
    oldValue: string,
    newValue: string,
  ) => {
    trackFormFieldEvent({
      formId: formId ?? 'unknown-form',
      formField: id ?? 'unknown-field',
      formFieldAction: action,
      formFieldOldValue: oldValue,
      formFieldNewValue: newValue,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target;
    const { value: oldValue } = rest;
    trackFieldEvent('update', oldValue || '', newValue);
    onChange && onChange(event, newValue);
  };

  return (
    <FormControl>
      <Typography variant="p1" component="label">
        {label}
      </Typography>
      <MUIRadioGroup {...rest} row onChange={handleChange}>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio disableRipple />}
            label={
              <Typography variant="p1" component="label">
                {option.label}
              </Typography>
            }
          />
        ))}
      </MUIRadioGroup>
    </FormControl>
  );
}
