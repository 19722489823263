import { SyntheticEvent, useState } from 'react';
import { styled } from '@frontend/theme';
import {
  Autocomplete,
  Button,
  FilePicker,
  IconButton,
  Modal,
  Select,
  StatusBar,
  Tab,
  TabPanel,
  Tabs,
  TextField,
  Toast,
  useTabs,
  RadioGroup,
} from '@frontend/ui-elements';
import {
  Box,
  Container,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import CheckDoubleFillIcon from 'remixicon-react/CheckDoubleFillIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import PhoneField from '../../app/shared/components/ui-csr/PhoneField/PhoneField';
import DatePicker from '../../app/shared/components/ui-csr/DatePicker/DatePicker';

export default function DesignSystem() {
  const theme = useTheme();
  const { handleChange, value } = useTabs({ initialTabIndex: 0 });
  const [phone, setPhone] = useState('');
  const [isStatusBarOpen, setIsStatusBarOpen] = useState(false);
  const [isSimplifiedStatusBar, setIsSimplifiedStatusBar] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isMemberOpen, setMemberIsOpen] = useState(false);
  const [openSnackbar, setSnackbarOpen] = useState(false);

  const handleSuccessModalOpen = () => setIsSuccessOpen(true);
  const handleSuccessModalClose = () => setIsSuccessOpen(false);
  const handleMemberModalOpen = () => setMemberIsOpen(true);
  const handleMemberModalClose = () => setMemberIsOpen(false);

  const handlePhoneChange = (newPhone: string) => {
    setPhone(newPhone);
  };
  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleStatusBarOpen = () => {
    setIsStatusBarOpen(!isStatusBarOpen);
  };

  const handleSimplfidiedStatusBarChange = () => {
    setIsSimplifiedStatusBar(!isSimplifiedStatusBar);
  };

  const handleSnackbarClose = (
    event?: SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const selectData = {
    id: 'select-id',
    label: 'Select Label',
    options: [
      { label: 'None', value: '' },
      { label: 'Option 1', value: 'option-1' },
      { label: 'Option 2', value: 'option-2' },
    ],
  };

  const statusData = {
    current: 2,
    stages: [
      {
        order: 0,
        label: 'Received',
        optional: 'Test',
      },
      {
        order: 1,
        label: 'Confirm details',
      },
      {
        order: 2,
        label: 'Sourcing',
        optional: 'Test',
      },
      {
        order: 3,
        label: 'pending',
      },
      {
        order: 4,
        label: 'In transit',
      },
      {
        order: 5,
        label: 'Arrived',
        optional: 'Test',
      },
    ],
  };

  const IconWrapper = styled(Box)(() => ({
    display: 'flex',
    position: 'relative',
    '& > svg': {
      '&:first-of-type': {
        zIndex: 1,
      },
      '&:last-of-type': {
        position: 'absolute',
        top: 3,
        left: 3,
      },
    },
  }));

  const ClosingIconButton = styled(IconButton)(() => ({
    position: 'absolute',
    top: '8px',
    right: '16px',
  }));

  return (
    <Container>
      <Stack maxWidth="desktopL" spacing={6}>
        <Container>
          <Typography component="h1" variant="display2">
            H1 Text
          </Typography>
        </Container>

        <Stack direction="column" maxWidth="mobile" spacing={4}>
          <TextField label="email" />
          <TextField label="email" error helperText="invalid input" />
          <TextField label="email" disabled />
        </Stack>

        <Stack direction="column" spacing={4} maxWidth="mobile">
          <Stack direction="row" spacing={4}>
            <Button buttonType="primary" size="small">
              Button CTA
            </Button>
            <Button buttonType="primary" size="small" disabled>
              Button CTA
            </Button>
          </Stack>
          <Stack direction="row" spacing={4}>
            <Button buttonType="primary" size="large">
              Button CTA
            </Button>
            <Button buttonType="primary" size="large" disabled>
              Button CTA
            </Button>
          </Stack>
          <Stack direction="row" spacing={4}>
            <Button buttonType="primary" size="large" isSubmitting={true}>
              Submission
            </Button>
          </Stack>
        </Stack>

        <Stack direction="column" spacing={4} maxWidth="mobile">
          <Stack direction="row" spacing={4}>
            <Button buttonType="secondary" size="small">
              Button CTA
            </Button>
            <Button buttonType="secondary" size="small" disabled>
              Button CTA
            </Button>
          </Stack>
          <Stack direction="row" spacing={4}>
            <Button buttonType="secondary" size="large">
              Button CTA
            </Button>
            <Button buttonType="secondary" size="large" disabled>
              Button CTA
            </Button>
          </Stack>
        </Stack>

        <Stack direction="column" spacing={4} maxWidth="mobileL">
          <Stack direction="row" spacing={4}>
            <Button buttonType="linked" size="small">
              Button CTA
            </Button>
            <Button buttonType="linked" size="small" disabled>
              Button CTA
            </Button>
          </Stack>

          <Stack direction="row" spacing={4}>
            <Button buttonType="linked" size="medium">
              Button CTA
            </Button>
            <Button buttonType="linked" size="medium" disabled>
              Button CTA
            </Button>
          </Stack>

          <Stack direction="row" spacing={4}>
            <Button buttonType="linked" size="large">
              Button CTA
            </Button>
            <Button buttonType="linked" size="large" disabled>
              Button CTA
            </Button>
          </Stack>
        </Stack>

        <Stack maxWidth="mobile" direction="column" spacing={4}>
          <Select
            id={selectData.id}
            label={selectData.label}
            options={selectData.options}
          />
          <Select
            id={selectData.id}
            label={selectData.label}
            options={selectData.options}
            disabled
          />
          <Select
            id={selectData.id}
            label={selectData.label}
            options={selectData.options}
            error
            helperText="There is an error with your selection"
          />
        </Stack>

        <Stack maxWidth="mobile" direction="column" spacing={4}>
          <Autocomplete
            id="auto1"
            label="Autocomplete"
            disablePortal
            options={selectData.options}
          />

          <Autocomplete
            id="auto2"
            label="Autocomplete Disabled"
            options={selectData.options}
            disablePortal
            disabled
          />

          <Autocomplete
            id="auto3"
            label="Autocomplete with errors"
            options={selectData.options}
            error
            helperText="Oh my! This Autocomplete has errors"
            disablePortal
          />
        </Stack>

        <Tabs value={value} onChange={handleChange} selectionFollowsFocus>
          <Tab label={'Tab 1'} />
          <Tab label={'Tab 2'} />
        </Tabs>
        <TabPanel index={0} value={value}>
          Item 1
        </TabPanel>
        <TabPanel index={1} value={value}>
          Item 2
        </TabPanel>

        <Stack direction="column" maxWidth="mobile" spacing={4}>
          <PhoneField
            defaultCountry="DE"
            label="Phone number"
            value={phone}
            onChange={handlePhoneChange}
          />
          <PhoneField
            defaultCountry="DE"
            label="Phone number"
            error
            helperText="Invalid phone number"
            value={phone}
            onChange={handlePhoneChange}
          />
          <PhoneField defaultCountry="DE" label="Phone number" disabled />
        </Stack>
      </Stack>

      <Box marginTop={4}>
        <Stack maxWidth="tablet" spacing={4}>
          <Button
            buttonType="primary"
            size="medium"
            onClick={handleMemberModalOpen}
          >
            Open "Already a member?" Modal
          </Button>
          <Modal open={isMemberOpen} onClose={handleMemberModalClose}>
            <Box padding="16px 24px">
              <Stack width={'100%'} useFlexGap spacing={'16px'}>
                <ClosingIconButton onClick={handleMemberModalClose}>
                  <CloseLineIcon size={24} color={theme.palette.neutral[500]} />
                </ClosingIconButton>
                <Stack
                  width={'100%'}
                  useFlexGap
                  spacing={'8px'}
                  alignItems="flex-start"
                >
                  <Typography variant="h4" marginTop="8px">
                    Already a member?
                  </Typography>
                  <Typography variant="p3">
                    Please enter your email here:
                  </Typography>
                </Stack>

                <TextField fullWidth label="Email" required />

                <Button
                  buttonType="primary"
                  size="large"
                  onClick={handleMemberModalClose}
                >
                  Log-in
                </Button>
              </Stack>
            </Box>
          </Modal>
        </Stack>

        <Stack maxWidth="tablet" spacing={4} marginTop={4}>
          <Button
            buttonType="primary"
            size="medium"
            onClick={handleSuccessModalOpen}
          >
            Open Successful Modal
          </Button>
          <Modal open={isSuccessOpen} onClose={handleSuccessModalClose}>
            <Box padding="40px">
              <Stack width={'100%'} useFlexGap spacing={'36px'}>
                <Stack
                  width={'100%'}
                  useFlexGap
                  spacing={'14px'}
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconWrapper>
                    <CheckDoubleFillIcon
                      size={98}
                      color={theme.palette.neutral[500]}
                    />
                    <CheckDoubleFillIcon
                      size={98}
                      color={theme.palette.secondary[500]}
                    />
                  </IconWrapper>
                  <Typography variant="h2">Successful!</Typography>
                  <Typography variant="p3">Request has been sent</Typography>
                </Stack>

                <Button
                  buttonType="primary"
                  size="large"
                  onClick={handleSuccessModalClose}
                >
                  Back to home-page
                </Button>
              </Stack>
            </Box>
          </Modal>
        </Stack>
      </Box>

      <Box marginTop={4}>
        <Stack maxWidth="tablet" spacing={4}>
          <DatePicker
            label="Offer valid until"
            helperText="Invalid input"
            required
          />
          <DatePicker
            label="Offer valid until"
            error
            helperText="Invalid input"
          />
          <DatePicker
            label="Offer valid until"
            helperText="Disabled input"
            disabled
          />
        </Stack>
      </Box>

      <Box marginTop={4}>
        <Stack maxWidth={theme.breakpoints.values.tablet} spacing={2}>
          <Button buttonType="primary" onClick={handleSnackbarOpen}>
            Open success snackbar
          </Button>

          <Toast
            snackbarProps={{
              open: openSnackbar,
              autoHideDuration: 5000,
              alertType: 'warning',
              onClose: handleSnackbarClose,
            }}
            alertProps={{
              onClose: handleSnackbarClose,
              severity: 'warning',
              title: 'This is the title',
              message: 'This is the message!',
            }}
          />
        </Stack>
      </Box>

      <Box marginTop={4}>
        <Stack maxWidth={theme.breakpoints.values.tablet} spacing={2}>
          <FilePicker
            id="filepicker"
            label={
              <Typography component="span" variant="p1">
                Upload photos of material
              </Typography>
            }
            fileUploadButtonText={
              <Typography component="span" variant="p1">
                Upload file
              </Typography>
            }
            onChange={files => console.log(files)}
          />
        </Stack>
      </Box>

      <Stack maxWidth={theme.breakpoints.values.tablet} spacing={2}>
        <Box
          marginTop={4}
          marginBottom={4}
          border={`1px solid ${theme.palette.primary[100]}`}
        >
          <Container>
            <FormControlLabel
              control={
                <Switch
                  name="simplified-status-bar"
                  checked={isSimplifiedStatusBar}
                  onChange={handleSimplfidiedStatusBarChange}
                />
              }
              label="Show closed OrderCard version on mobile"
            />

            <StatusBar
              activeStep={statusData.current}
              isSimplified={isSimplifiedStatusBar}
              onClick={handleStatusBarOpen}
              open={isStatusBarOpen}
              stages={statusData.stages}
            />
          </Container>
        </Box>
      </Stack>

      <Box marginTop={4}>
        <Stack maxWidth={theme.breakpoints.values.tablet} spacing={2}>
          <Container>
            <RadioGroup
              label="Is this also your Whatsapp number?"
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
              value="yes"
            />
          </Container>
        </Stack>
      </Box>
    </Container>
  );
}
