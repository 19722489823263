import { MainWrapper } from './OrderCardDetails.css';
import { IOrderCardDetails } from './OrderCardDetails.types';
import OrderBasicDetails from './OrderBasicDetails/OrderBasicDetails';

export default function OrderCardDetails({
  order,
  refs: { orderBasicDetailsSectionRef },
}: IOrderCardDetails) {
  return (
    <MainWrapper>
      <OrderBasicDetails
        order={order}
        orderBasicDetailsSectionRef={orderBasicDetailsSectionRef}
      />
    </MainWrapper>
  );
}
