import { styled, theme } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const MainWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& > *': {
    borderTop: `1px solid ${theme?.palette.neutral[50]}`,
  },
  '& > *:first-of-type': {
    borderTop: 'none',
  },
}));
