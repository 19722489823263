import { Translate } from '@frontend/translation';
import { EmptySpace } from '@frontend/ui-elements';
import { Typography, useTheme } from '@mui/material';
import {
  EstimationWrapper,
  LocationDetailsWrapper,
  LocationProgressBarWrapper,
  ProgressBar,
  SpaceBetweenWrapper,
} from './LocationProgressBar.css';
import { formatUnixTimestampToDateWithSpaces } from '../../../../../../../../../../../app/shared/utils/date.utils';
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon';
import LocationMarker from '../LocationMarker/LocationMarker';
import {
  LocationProgressBarTimeItemProps,
  LocationProgressBarProps,
} from './LocationProgressBar.types';

const LocationProgressBar = ({
  actualTimeOfArrival,
  actualTimeOfDeparture,
  arrivalPort,
  departurePort,
  expectedTimeOfArrival,
  expectedTimeOfDeparture,
  shippingCompany,
  value,
}: LocationProgressBarProps) => {
  const theme = useTheme();

  const TimeItem = ({
    actualTime,
    expectedTime,
    isDeparture,
  }: LocationProgressBarTimeItemProps) => {
    const isActual = !!actualTime;
    const timeTranslation = `${isActual ? 'actual' : 'estimated'}-time-of-${isDeparture ? 'departure' : 'arrival'}`;
    const translationId =
      'customer-platform.request-details.order-list.container-drawer.container-tracking.location-progress-bar.' +
      timeTranslation;
    return (
      <Typography variant="p4">
        <Translate id={translationId} />
        <EmptySpace />
        {isActual && actualTime !== null
          ? formatUnixTimestampToDateWithSpaces(actualTime)
          : expectedTime !== null &&
            formatUnixTimestampToDateWithSpaces(expectedTime)}
      </Typography>
    );
  };

  return (
    <LocationDetailsWrapper>
      <SpaceBetweenWrapper>
        {departurePort && (
          <Typography variant="p2">
            {departurePort?.name}, {departurePort?.countryCode}
          </Typography>
        )}
        {arrivalPort && (
          <Typography variant="p2">
            {arrivalPort?.name}, {arrivalPort?.countryCode}
          </Typography>
        )}
      </SpaceBetweenWrapper>

      <LocationProgressBarWrapper>
        <LocationMarker left={0} active />
        <LocationMarker left={100} />
        <LocationMarker left={value} active>
          {value < 100 ? (
            <ArrowRightLineIcon size={12} color={theme.palette.primary[500]} />
          ) : undefined}
        </LocationMarker>
        <ProgressBar value={value} />
      </LocationProgressBarWrapper>

      <SpaceBetweenWrapper>
        <EstimationWrapper>
          <TimeItem
            actualTime={actualTimeOfDeparture}
            expectedTime={expectedTimeOfDeparture}
            isDeparture
          />
          <TimeItem
            actualTime={actualTimeOfArrival}
            expectedTime={expectedTimeOfArrival}
            isDeparture={false}
          />
        </EstimationWrapper>
        <Typography variant="p4">{shippingCompany}</Typography>
      </SpaceBetweenWrapper>
    </LocationDetailsWrapper>
  );
};

export default LocationProgressBar;
