import { useEffect, useRef } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { useGetListingOrderList } from '@frontend/api';
import {
  IGenericFile,
  IMAGE_VARIANT,
  useDownloadAllImages,
} from '@frontend/api';
import { head } from 'lodash';
import {
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Container,
  ListWrapper,
  LoaderWrapper,
  MainWrapper,
} from './OrderList.css';
import { IOrderList } from './OrderList.types';
import { useGetDistanceToTop } from '../../../../app/shared/utils/useGetDistanceToTop/useGetDistanceToTop';
import ImageListWithMagnifier from '../../../../app/shared/components/ImageListWithMagnifier/ImageListWithMagnifier';
import EmptyOrder from '../../../../app/shared/components/EmptyOrder/EmptyOrder';

import OrderCard from './OrderCard/OrderCard';

export default function OrderList({ listing }: IOrderList) {
  const { status: getListingOrderListStatus, data: listingOrderListResponse } =
    useGetListingOrderList(listing.uuid);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const refHTMLElement = useRef<HTMLDivElement>(null);
  const distanceToTop = useGetDistanceToTop({ elementRef: refHTMLElement });
  const { downloadAllImages } = useDownloadAllImages();

  useEffect(() => {
    if (!isMobile && !orderId && listingOrderListResponse?.data.length) {
      const firstOrder = head(listingOrderListResponse?.data);
      if (firstOrder) {
        const urlDestination = generatePath(
          PLATFORM_ROUTES.listingOrderDetails,
          {
            uuid: listing.uuid,
            orderId: firstOrder.uuid,
          },
        );
        navigate(
          {
            pathname: urlDestination,
          },
          {
            state: { disableScrollToCard: true },
            replace: true,
          },
        );
      }
    }
    // Preventing navigate and orderID to be in the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, listingOrderListResponse, listing.uuid]);

  const handleChange = (clickedOrderUuid: string) => {
    const urlDestination =
      orderId === clickedOrderUuid
        ? generatePath(PLATFORM_ROUTES.listingDetails, {
            uuid: listing.uuid,
          })
        : generatePath(PLATFORM_ROUTES.listingOrderDetails, {
            uuid: listing.uuid,
            orderId: clickedOrderUuid,
          });
    navigate(urlDestination, { replace: true });
  };

  if (getListingOrderListStatus === 'success') {
    if (orderId && isMobile) {
      const currentOrder = listingOrderListResponse.data.find(
        order => order.uuid === orderId,
      );
      if (currentOrder) {
        return (
          <OrderCard
            order={currentOrder}
            isOrderSelected={currentOrder.uuid === orderId}
            enableScroll={
              currentOrder.uuid === orderId &&
              !location.state?.disableScrollToCard
            }
          />
        );
      }
    }
  }

  return (
    <MainWrapper ref={refHTMLElement} distance={distanceToTop}>
      <Container>
        <Typography variant={isMobile ? 'h4' : 'h5'}>
          <Translate
            id="customer-platform.listing-details.order-list.order-listing-number"
            values={{
              LISTING_NUMBER_ID: listing.listingNumberId,
            }}
          />
        </Typography>
        <ImageListWithMagnifier
          uuid={listing.uuid}
          imageList={listing.photos as IGenericFile[]}
          variant={IMAGE_VARIANT.LISTING}
          displayAllImages={false}
          downloadAllOnClick={() =>
            downloadAllImages(
              listing.uuid,
              IMAGE_VARIANT.LISTING,
              listing.listingNumberId,
            )
          }
        />
        <ListWrapper>
          {getListingOrderListStatus === 'pending' ? (
            <LoaderWrapper>
              <CircularProgress size={160} />
            </LoaderWrapper>
          ) : getListingOrderListStatus === 'success' ? (
            listingOrderListResponse?.data.length ? (
              listingOrderListResponse?.data.map(order => (
                <OrderCard
                  key={order.uuid}
                  order={order}
                  isOrderSelected={order.uuid === orderId}
                  onChange={() => handleChange(order.uuid)}
                  enableScroll={
                    order.uuid === orderId &&
                    !location.state?.disableScrollToCard
                  }
                />
              ))
            ) : (
              <EmptyOrder />
            )
          ) : (
            <EmptyOrder />
          )}
        </ListWrapper>
      </Container>
    </MainWrapper>
  );
}
