import { Translate } from '@frontend/translation';
import { Button, Modal } from '@frontend/ui-elements';
import { Typography, useTheme } from '@mui/material';
import AlertLineIcon from 'remixicon-react/AlertLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { IEmailVerifcationLinkExpiredModal } from './EmailVerificationLinkExpiredModal.types';
import {
  IconButton,
  IconWrapper,
  ModalBox,
  ModalHeadingBox,
  ModalTextBox,
} from './EmailVerificationLinkExpiredModal.css';
import { useIntl } from 'react-intl';

export default function EmailVerificationLinkExpiredModal({
  onClose,
  onClick,
}: IEmailVerifcationLinkExpiredModal) {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Modal open={true}>
      <ModalBox>
        <ModalHeadingBox>
          <IconButton
            id="email-verification-link-expired-modal.icon-button.close-modal"
            aria-label={intl.formatMessage({
              id: 'customer-platform.shared.email-verification.modal-box.close',
            })}
            onClick={onClose}
          >
            <CloseLineIcon size={24} color={theme.palette.neutral[500]} />
          </IconButton>
          <IconWrapper>
            <AlertLineIcon size={76} color={theme.palette.neutral[500]} />
            <AlertLineIcon size={76} color={theme.palette.secondary[500]} />
          </IconWrapper>

          <ModalTextBox>
            <Typography variant="h2">
              <Translate id="customer-platform.shared.email-verification.link-expired-modal.link-expired" />
            </Typography>
            <Typography variant="p3">
              <Translate id="customer-platform.shared.email-verification.link-expired-modal.request-verification-again" />
            </Typography>
          </ModalTextBox>
        </ModalHeadingBox>

        <Button
          buttonType="primary"
          data-testid="expired-link"
          id="email-verification-link-expired-modal.button.verify-again"
          size="large"
          onClick={onClick}
        >
          <Translate id="customer-platform.shared.email-verification.link-expired-modal.cta" />
        </Button>
      </ModalBox>
    </Modal>
  );
}
